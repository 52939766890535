$(function(){
    svg4everybody();
    
    var iOSMatch = navigator.appVersion.match(/iPhone/) || navigator.appVersion.match(/iPad/);
        var versionMatch = navigator.appVersion.match(/iPhone OS ([0-9_]+) /);
        var version = versionMatch && versionMatch.length ? versionMatch.pop().split('_')[0] : false;

        if (iOSMatch === 'iPhone OS' && version !== '10')  {
            document.write('<meta http-equiv="Content-Security-Policy" content="default-src * \'unsafe-inline\' \'unsafe-eval\' data: blob:;">');
        }
})
